import React from 'react';
import chroma from 'chroma-js';
import Select from 'react-select';
import { useQuery } from 'urql';
import { Tag } from '../types';

export type TagUI = {
    id: string,
    value: string,
    label: string,
    color: string
}

const getTagsQuery = `
query MyQuery {
    tags {
      name
      uuid
      color
    }
  }
`
const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colorStyles = {
    control: (styles: React.CSSProperties) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles: React.CSSProperties, { data, isDisabled, isFocused, isSelected }:
        { data: Tag, isDisabled: boolean, isFocused: boolean, isSelected: boolean }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            // color: isDisabled
            //     ? '#ccc'
            //     : isSelected
            //         ? chroma.contrast(color, 'white') > 2
            //             ? 'white'
            //             : 'black'
            //         : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                // @ts-ignore
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: (styles: React.CSSProperties) => ({ ...styles, ...dot() }),
    placeholder: (styles: React.CSSProperties) => ({ ...styles, ...dot() }),
    singleValue: (styles: React.CSSProperties, { data }: { data: Tag }) => ({ ...styles, ...dot(data.color) }),
};
export type TagsProps = {
    onChange: Function
    initialValue?: Tag
}

export default (props: TagsProps) => {

    const [getTagsResult, reexecuteQueryTags] = useQuery({ query: getTagsQuery });
    const { data, fetching, error } = getTagsResult;
    if (fetching || error) {
        return <p>loading...</p>
    }

    const result: TagUI[] = data.tags.map((tag: Tag) => ({
        id: tag.uuid,
        value: tag.name,
        label: tag.name,
        color: tag.color
    }))
    const defaultValue = props.initialValue ? {
        id: props.initialValue.uuid,
        value: props.initialValue.name,
        label: props.initialValue.name,
        color: props.initialValue.color
    } : result[0]
    return (
        <Select defaultValue={defaultValue} label="tagsSelect" onChange={(data: any) => {
            props.onChange({ name: data.name, uuid: data.id, color: data.color })
        }}
            // @ts-ignore
            options={result} styles={colorStyles} />
    )
};