import React, { Component } from 'react';
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Attachment } from '../types';
type LightBoxProps = {
    data: Attachment[],
    onSelect?: Function,
    selectedFiles?: Attachment[],
    mandatoryFiles?: Attachment[],
    size?: 'sm' | 'md',
    maxSelection?: number
}
export default ({ data, onSelect, selectedFiles, size = 'sm', maxSelection = 5, mandatoryFiles = [] }: LightBoxProps) => {
    const [imageIndex, setImageIndex] = React.useState<number | null>(null);
    const imageList = data.map(dt => ({ url: dt.fileURL, title: dt.fileName }))
    const [selected, setSelected] = React.useState<Attachment[]>(selectedFiles || []);
    const [selectedMandatory, setMandatorySelected] = React.useState<Attachment[]>(mandatoryFiles || []);
    React.useEffect(() => {
        if (selectedFiles)
            setSelected(selectedFiles)
    }, [selectedFiles])

    return (
        <>
            {
                data.map((file, index) => {
                    return (
                        <div key={file.uuid} className="d-flex justify-content-center align-items-center flex-column-reverse">
                            {onSelect && <span className="n-chk ml-4">
                                <label className="new-control new-checkbox new-checkbox-rounded checkbox-primary">
                                    <input type="checkbox" className="new-control-input"
                                        checked={selected.some(f => f.uuid == file.uuid)}
                                        disabled={selectedMandatory.some(f => f.uuid == file.uuid)}
                                        onChange={(event: React.ChangeEvent<{ checked: boolean }>) => {
                                            if (selected.length < maxSelection && event.target.checked) {
                                                setSelected([...selected, file])
                                                onSelect([...selected, file])
                                            } else {
                                                setSelected(selected.filter(f => f.uuid != file.uuid))
                                                onSelect(selected.filter(f => f.uuid != file.uuid))
                                            }
                                        }} />
                                    <span className="new-control-indicator" />
                                </label>
                            </span>}
                            <img
                                onClick={() => setImageIndex(index)}
                                key={file.uuid} alt="image-gallery"
                                src={file.fileURL || "/assets/img/350x250.jpg"}
                                className={`img-fluid ml-4 mt-4 ${onSelect ? 'mb-1' : 'mb-4'}`}
                                style={{
                                    cursor: 'pointer',
                                    borderRadius: 10,
                                    ...(size == 'md' ? { width: 200, height: 150 } : { width: 100, height: 75 })
                                }} />
                        </div>
                    )
                })
            }
            {imageIndex !== null && <Lightbox startIndex={imageIndex} images={imageList} onClose={() => setImageIndex(null)} />}
        </>

    )

}