import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'urql';
import { toast } from 'react-toastify';
import MultiTag from '../Components/MultiTag';
import { useParams, useHistory } from "react-router-dom";
import PhoneView from '../Components/PhoneView';
import { v4 as uuid } from 'uuid';
import {
    Switch,
    Route,
    Redirect,
    Link
} from "react-router-dom";
import jwt_decode from 'jwt-decode';
import keycloak from "../keycloak";
import { JwtToken, publishSchedules, NewsTag, Tag, ApproveBox, Attachment } from '../types';
import LightBox from '../Components/LightBox';
import Modal from '../Components/Modal';
import Accordion from '../Components/Accordion';
import FileUpload, { WrapperCss } from '../Components/FileUpload';
import ImageSelector from '../Components/ImageSelector';
import { timeAgo } from '../index';


const setApproveFalseQuery = `
mutation ($uuid: String!) {
    update_contentBox_by_pk(pk_columns: {uuid: $uuid}, _set: {isApproved: false}) {
      uuid
    }
  }  
`
const getApproveQuery = `
query ($limit: Int!, $offset: Int!) {
    approvedBox(limit: $limit, offset: $offset, order_by: {lastApprovedTime: desc}) {
      title
      shortContent
      tag {
        name
        color
        uuid
      }
      uuid
      timestamp
      lastApprovedTime
      contentBox{
        uuid
        isApproved
        isPublished
      }
      approveCommentBoxes_aggregate {
        nodes {
          comment
          user {
            name
            id
            picture
          }
          contentBox {
            isApproved
            isPublished
            uuid
          }
        }
      }
    }
    tags {
      name
      uuid
      color
    }
  }
  
`
const PublishLayout = () => {
    let history = useHistory();
    const [isToggle, setIsToggle] = React.useState<boolean>(false);
    const [doShowLockModal, setDoShowLockModal] = React.useState<ApproveBox | null>(null);
    const [limit, setLimit] = React.useState<number>(10);
    const [offset, setOffset] = React.useState<number>(0);
    const [setApproveFalseResult, setApproveFalse] = useMutation(setApproveFalseQuery);
    const [getApproveResult, reexecuteQueryApprove] = useQuery({
        query: getApproveQuery,
        variables: {
            limit,
            offset
        }
    });
    const refresh = () => reexecuteQueryApprove({ requestPolicy: 'network-only' });
    const unlockArticle = () => {
        setApproveFalse({ "uuid": doShowLockModal?.contentBox?.uuid });
        setDoShowLockModal(null)
    }
    useEffect(() => {
        refresh()
    }, [limit, offset])
    const { data, fetching, error } = getApproveResult;
    return (
        <>
            <div className="mail-box-container">
                <div id="mailbox-inbox" className="accordion mailbox-inbox">
                    <div className="search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                        <input type="text" className="form-control input-search" placeholder="Search Here..." />
                    </div>
                    <div className="action-center">
                        <div className="">
                            <div className="n-chk">
                                <label className="new-control new-checkbox checkbox-primary">
                                    Select date
                                </label>
                            </div>
                        </div>
                        <div className="">
                            <div onClick={refresh}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
                            </div>

                        </div>
                    </div>
                    <div className="row app-notes layout-top-spacing" id="cancel-row">
                        <div className="col-lg-12">
                            <div className="app-hamburger-container">
                                <div className="hamburger" onClick={() => setIsToggle(!isToggle)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu chat-menu d-xl-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                                </div>
                            </div>
                            <div className="app-container">
                                <div className="app-note-container">
                                    <div onClick={() => setIsToggle(!isToggle)} className={`app-note-overlay ${isToggle ? 'app-note-overlay-show' : ''}`}></div>
                                    <div className={`tab-title ${isToggle ? 'note-menu-show' : ''}`}>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12">
                                                <ul className="nav nav-pills d-block" id="pills-tab3" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link list-actions active" id="all-notes">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                                            All Articles
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link list-actions" id="note-fav">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                                            Top Artciles
                                                        </a>
                                                    </li>
                                                </ul>
                                                <p className="group-section"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7" y2="7"></line></svg> Verticals</p>
                                                {
                                                    (fetching || error) ? (<p>loading..</p>) : (
                                                        <ul className="nav nav-pills d-block group-list" role="tablist">
                                                            {
                                                                data.tags.map((tag: Tag) => (
                                                                    <li key={tag.uuid} className="nav-item" style={{ position: 'relative' }}>
                                                                        <div style={{
                                                                            width: 10, height: 10, background: tag.color, display: 'block',
                                                                            position: 'absolute', top: 10, left: 25, borderRadius: '50%'
                                                                        }} />
                                                                        <a className="nav-link list-actions" ><span>{tag.name}</span></a>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="note-container note-grid">
                                        {
                                            (fetching || error) ? (<p>loading..</p>) : (
                                                data.approvedBox.map((item: ApproveBox) => {
                                                    return (
                                                        <div key={item.uuid} className="note-item all-notes">
                                                            <div role="button"
                                                                onClick={() => history.push(`/publish/${item.uuid}`)}
                                                                className="widget widget-five " style={{
                                                                    "height": "fit-content",
                                                                    "borderBottomRightRadius": 0,
                                                                    "borderBottomLeftRadius": 0,
                                                                    "borderBottom": `3px solid ${item.tag?.color}`,
                                                                    "backgroundColor": item.contentBox?.isApproved ? '#fff' : '#fff5f5'
                                                                }}>
                                                                <div className="widget-heading">
                                                                    <div className="task-info">
                                                                        <div className="media m-0">
                                                                            <img className="align-self-center"
                                                                                src={item.contentBox?.user?.picture || "/assets/img/90x90.jpg"}
                                                                                alt={item.contentBox?.user?.name} />
                                                                        </div>
                                                                        <div className="w-title">
                                                                            <h5>{item.title}</h5>
                                                                            <span>{new Date(item.lastApprovedTime).toLocaleString()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-action">
                                                                        <div className="dropdown">
                                                                            {item.contentBox?.isApproved && <a role="button" className="mr-1">
                                                                                <svg onClick={(event: React.MouseEvent) => {
                                                                                    event.stopPropagation()
                                                                                    console.log('d')
                                                                                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" /></svg>
                                                                            </a>}
                                                                            {item.contentBox?.isApproved && <a role="button" className="ml-1">
                                                                                <svg onClick={(event: React.MouseEvent) => {
                                                                                    event.stopPropagation()
                                                                                    setDoShowLockModal(item)
                                                                                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2" /><path d="M7 11V7a5 5 0 0 1 10 0v4" /></svg>
                                                                            </a>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="widget-content">
                                                                    <p>{item.shortContent}</p>

                                                                    {/* <div className="progress-data">
                                                                        <div className="progress-info">
                                                                            <div className="task-count"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg><p>5 Tasks</p></div>
                                                                            <div className="progress-stats"><p>86.2%</p></div>
                                                                        </div>
                                                                        <div className="progress">
                                                                            <div className="progress-bar bg-primary" role="progressbar" style={{ "width": "65%" }}></div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="meta-info">
                                                                        <div className="due-time">
                                                                            <p>
                                                                                {item.contentBox?.isPublished ? 'Published' : 'Pending'}
                                                                            </p>
                                                                        </div>
                                                                        <div className="avatar--group">
                                                                            <div className="avatar translateY-axis more-group">
                                                                                <span className="avatar-title">+6</span>
                                                                            </div>
                                                                            <div className="avatar translateY-axis">
                                                                                <img alt="avatar" src="/assets/img/90x90.jpg" />
                                                                            </div>
                                                                            <div className="avatar translateY-axis">
                                                                                <img alt="avatar" src="/assets/img/90x90.jpg" />
                                                                            </div>
                                                                            <div className="avatar translateY-axis">
                                                                                <img alt="avatar" src="/assets/img/90x90.jpg" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal open={doShowLockModal ? true : false}
                title={`Allow edit`}
                onSubmitText={'Yes'}
                onSubmit={unlockArticle}
                onClose={() => setDoShowLockModal(null)}>
                <>
                    <p>Are you sure you want to allow edit for</p>
                    <h6>{doShowLockModal?.title}</h6>
                </>
            </Modal>
        </>
    )
}
const getAppoveQueryByPk = `
query MyQuery($uuid: String!) {
    approvedBox_by_pk(uuid: $uuid) {
      title
      html
      shortContent
      tag {
        name
        color
        uuid
      }
      uuid
      url
      timestamp
      bannerImg
      lastApprovedTime
      imageBoxes{
        name
        fileURL
        uuid
      }
      contentBox {
        uuid
        isApproved
        isPublished
        user{
            picture
            name
            email
        }
      }
      newsTagsBoxes{
        newsTag{
          label
          value
        }
      }
      approveCommentBoxes_aggregate {
        nodes {
          comment
          user {
            name
            id
            picture
          }
          contentBox {
            isApproved
            isPublished
            uuid
          }
        }
      }
    }
  }  
`
const PublishItem = () => {
    let { id } = useParams<{ id: string }>();
    let history = useHistory();
    const [getAppoveQueryByPkResult, reexecuteQueryApproveByPK] = useQuery({ query: getAppoveQueryByPk, variables: { uuid: id } });
    const { data, fetching, error } = getAppoveQueryByPkResult;
    const refresh = () => {
        // Refetch the query and skip the cache
        reexecuteQueryApproveByPK({ requestPolicy: 'network-only' });
    };
    React.useEffect(() => {
        refresh()
    }, [id])

    return (
        <>
            <div className="row">
                <div id="tabsLine" className="col-lg-12 col-12 layout-spacing">
                    <div className="statbox widget box box-shadow">
                        <div className="widget-header">
                            <div className="row">
                                <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                    <h4>
                                        <svg onClick={() => history.push('/publish')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" role="button" className="mr-3 feather feather-arrow-left close-message"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                        {`Approve Item: ${id}`}
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="widget-content widget-content-area underline-content">

                            <ul className="nav nav-tabs  mb-3" id="animateLine" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="animated-underline-home-tab" data-toggle="tab" href="#animated-underline-home" role="tab" aria-controls="animated-underline-home" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                        Info
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="animated-underline-profile-tab" data-toggle="tab" href="#animated-underline-profile" role="tab" aria-controls="animated-underline-profile" aria-selected="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2" /><circle cx="8.5" cy="8.5" r="1.5" /><polyline points="21 15 16 10 5 21" /></svg>
                                        Image
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="animated-underline-contact-tab" data-toggle="tab" href="#animated-underline-contact" role="tab" aria-controls="animated-underline-contact" aria-selected="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share-2"><circle cx="18" cy="5" r="3" /><circle cx="6" cy="12" r="3" /><circle cx="18" cy="19" r="3" /><line x1="8.59" y1="13.51" x2="15.42" y2="17.49" /><line x1="15.41" y1="6.51" x2="8.59" y2="10.49" /></svg>
                                        Share
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="animated-underline-publish-tab" data-toggle="tab" href="#animated-underline-publish" role="tab" aria-controls="animated-underline-contact" aria-selected="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16" /><line x1="12" y1="12" x2="12" y2="21" /><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" /><polyline points="16 16 12 12 8 16" /></svg>
                                        Publish
                                    </a>
                                </li>
                            </ul>

                            <div className="tab-content" id="animateLineContent-4">
                                <div className="tab-pane fade show active" id="animated-underline-home" role="tabpanel" aria-labelledby="animated-underline-home-tab">
                                    {
                                        data ? <PublishInfo approvedData={data['approvedBox_by_pk']} refresh={refresh} /> : null
                                    }
                                </div>
                                <div className="tab-pane fade" id="animated-underline-profile" role="tabpanel" aria-labelledby="animated-underline-profile-tab">
                                    {
                                        data ? <PublishImage approvedData={data['approvedBox_by_pk']} refresh={refresh} /> : null
                                    }
                                </div>
                                <div className="tab-pane fade" id="animated-underline-contact" role="tabpanel" aria-labelledby="animated-underline-contact-tab">
                                    <p className="dropcap  dc-outline-primary">
                                        work in progress
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="animated-underline-publish" role="tabpanel" aria-labelledby="animated-underline-publish-tab">
                                    <p className="dropcap  dc-outline-primary">
                                        {
                                            data ? <Publish approvedData={data['approvedBox_by_pk']} refresh={refresh} /> : null
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

type PublishProps = {
    approvedData: ApproveBox,
    refresh: Function
}
const insertNewsTagsQuery = `
mutation ($data: [newsTagsBox_insert_input!]!,$approveBox_ID: String!) {
    delete_newsTagsBox(where: {approveBox_ID: {_eq: $approveBox_ID}}) {
        affected_rows
    }
    insert_newsTagsBox(objects: $data) {
      affected_rows
    }
  }  
`
const PublishInfo = ({ approvedData, refresh }: PublishProps) => {
    console.log(approvedData)
    const [newsTags, setNewsTags] = React.useState<NewsTag[]>(approvedData.newsTagsBoxes?.map(x => x.newsTag) || []);
    const [insertNewsTagsResult, insertNewsTags] = useMutation(insertNewsTagsQuery);
    const addTags = () => {
        insertNewsTags({
            approveBox_ID: approvedData.uuid,
            data: newsTags.map((tag) => ({
                approveBox_ID: approvedData.uuid,
                newsTags_ID: tag.value,
                uuid: uuid()
            }))
        })
        refresh()
    }
    return (
        <div className="publishInfo row layout-top-spacing">
            <div className="htmlArea cardBox col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
                <h2 className="text-center mb-3">{approvedData.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: approvedData.html || 'ERROR' }} />
            </div>
            <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing mail-content-container">
                <ul className="cardBox list-unstyled">
                    {
                        approvedData?.approveCommentBoxes_aggregate?.nodes.map((publishedComment) => (
                            <li className="media">
                                <img className="align-self-center rounded"
                                    src={publishedComment?.user?.picture || "/assets/img/90x90.jpg"}
                                    alt={publishedComment?.user?.name} />
                                <div className="media-body">
                                    <h4 className="media-heading">
                                        <span className="media-title mr-2"> {publishedComment?.user?.name}</span>
                                        <span className="badge badge-primary">Published</span>
                                    </h4>
                                    <p className="media-text"> {new Date(publishedComment.timestamp).toLocaleString()}</p>
                                    <p className="media-text"> {publishedComment.comment}</p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
                <form>

                    <div className="form-group row mb-4">
                        <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">Short Content</label>
                        <div className="col-xl-10 col-lg-9 col-sm-10">
                            <input value={approvedData.shortContent} className="form-control" disabled />
                        </div>
                    </div>
                    <div className="form-group row mb-4">
                        <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">News Tags</label>
                        <div className="col-xl-10 col-lg-9 col-sm-10">
                            <MultiTag
                                initialValue={newsTags}
                                onChange={(data: NewsTag[]) => setNewsTags(data)} />
                        </div>
                    </div>

                </form>
            </div>
            <div className="form-group row w-100 text-right">
                <div className="col-sm-12">
                    <button onClick={addTags} type="submit" className="btn btn-primary mt-3">Update News Tags</button>
                </div>
            </div>
        </div>
    )
}
const updateBannerImgQuery = `
mutation ($uuid: String!, $bannerImg: String!) {
    update_approvedBox_by_pk(pk_columns: {uuid: $uuid}, _set: {bannerImg: $bannerImg}) {
      bannerImg
    }
  }
`
const PublishImage = ({ approvedData, refresh }: PublishProps) => {
    console.log(approvedData)
    const [previewHtml, setPreviewHtml] = React.useState<string>('');
    const [previewBannerImg, setPreviewBannerImg] = React.useState<string>(approvedData?.bannerImg || '');
    const [bannerImg, setBannerImg] = React.useState<string>('');
    const [updateBannerImgResult, updateBannerImg] = useMutation(updateBannerImgQuery);
    const refreshPreview = async () => {
        const refreshPreviewResponse = await fetch("https://api.nivendha.me/build/article", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                data: approvedData,
                previewBannerImg,
                previewPath: `publishBox/${approvedData.uuid}`
            })
        }).then(response => response.text())
            .catch(error => console.log('build_error', error));
        setPreviewHtml(refreshPreviewResponse || '')
    }
    const uploadPrviewImg = async () => {
        const bannerImg = await fetch("https://api.nivendha.me/build/bannerImg", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                base64: previewBannerImg,
                name: 'banner',
                path: `publishBox/${approvedData.uuid}`
            })
        }).then(response => response.json())
            .catch(error => console.log('build_bannerImg_error', error));
        await updateBannerImg({
            uuid: approvedData.uuid,
            bannerImg: bannerImg.data
        })
        toast('Banner image updated');
        refresh()
    }
    useEffect(() => {
        refreshPreview()
    }, [previewBannerImg])
    return (
        <div className="row layout-top-spacing">
            <div className=" cardBox col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
                <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">Banner image</label>
                <ImageSelector
                    onChange={async (base64Img: string) => {
                        setPreviewBannerImg(base64Img)
                    }}
                    data={approvedData.imageBoxes} />
            </div>
            <div className=" col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
                <PhoneView html={previewHtml} />
            </div>
            <div className="form-group row w-100 text-right">
                <div className="col-sm-12">
                    <button onClick={refreshPreview} className="btn btn-primary mt-3">Refresh</button>
                    <button onClick={uploadPrviewImg} className="btn btn-primary ml-3 mt-3">Save</button>
                </div>
            </div>
        </div>
    )
}
const updateURLQuery = `
mutation ($uuid: String!, $contentBoxID: String!, $url: String!) {
    update_approvedBox_by_pk(pk_columns: {uuid: $uuid}, _set: {url: $url}) {
      url
    }
    update_contentBox_by_pk(pk_columns: {uuid: $contentBoxID}, _set: {isPublished: true}) {
      isApproved
    }
  }  
`
const Publish = ({ approvedData, refresh }: PublishProps) => {
    console.log(approvedData)
    const [fileURL, setFileURL] = React.useState<string>(approvedData.url || '');
    const [commitMsg, setCommitMsg] = React.useState<string>(`${approvedData.contentBox?.user?.name} uploaded this article`);
    const [scheduledAt, setScheduledAt] = React.useState<publishSchedules>('now');
    const [updateURLResult, updateURL] = useMutation(updateURLQuery);
    const getHugoCode = () => {
        return `---
title: "${approvedData.title}"
date: ${approvedData.timestamp}
featured_image: "${approvedData.bannerImg}"
tags: [${approvedData.newsTagsBoxes?.map(dt => dt.newsTag.label)}]
vertical: "${approvedData.tag?.name}"
short_content: "${approvedData.shortContent}"
author_name: "${approvedData.contentBox?.user?.name}"
author_img: "${approvedData.contentBox?.user?.picture}"
author_email: "${approvedData.contentBox?.user?.email}"
disable_share: false

---
${approvedData.html}
`;
    }
    const publishToHugo = async () => {
        if (fileURL == '') {
            toast('Please fill valid URL');
            return;
        }
        try {
            await updateURL({
                uuid: approvedData.uuid,
                contentBoxID: approvedData.contentBox?.uuid,
                url: fileURL
            })
            fetch(`https://build.nivendha.me/generic-webhook-trigger/invoke?token=${scheduledAt == 'now' ? 'buildMaster' : 'buildArticle'}`, {
                method: 'POST',
                mode: "no-cors",
                headers: { "Content-Type": "application/json" },
                body: scheduledAt == 'now' ?
                    JSON.stringify({
                        "fileName": fileURL,
                        "message": commitMsg,
                        "hugoBody": getHugoCode()
                    })
                    : JSON.stringify({
                        "gitBranch": scheduledAt,
                        "fileName": fileURL,
                        "message": commitMsg,
                        "hugoBody": getHugoCode()
                    })
            })
            toast('Successfully Uploaded')
        }
        catch (e) {
            toast('Error in upload')
            console.log(e)
        }
    }

    return (
        <div className="publishInfo row layout-top-spacing">
            <div className="htmlArea cardBox col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 layout-spacing">
                <code>
                    <p> {`title: "${approvedData.title}"`}</p>
                    <p> {`date: ${approvedData.timestamp}`}</p>
                    <p> {`featured_image: "${approvedData.bannerImg}"`}</p>
                    <p> {`tags: [${approvedData.newsTagsBoxes?.map(dt => dt.newsTag.label)}"]`}</p>
                    <p> {`vertical: "${approvedData.tag?.name}"`}</p>
                    <p> {`short_content: "${approvedData.shortContent}"`}</p>
                    <p> {`author_name: "${approvedData.contentBox?.user?.name}"`}</p>
                    <p> {`author_img: "${approvedData.contentBox?.user?.picture}"`}</p>
                    <p> {`author_email: "${approvedData.contentBox?.user?.email}"`}</p>
                    <p> ---</p>
                    <p> {approvedData.html}</p>
                </code>
            </div>
            <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing mail-content-container">

                <div className="form-group row mb-4">
                    <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">Enter URL</label>
                    <div className="col-xl-10 col-lg-9 col-sm-10">
                        <input value={fileURL}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                let url = event.target.value;
                                url = url.replace(/[^a-zA-Z -]/g, "").replace(/ /g, '-')
                                setFileURL(url)
                            }}
                            className="form-control" />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">Enter Publisg Msg</label>
                    <div className="col-xl-10 col-lg-9 col-sm-10">
                        <input value={commitMsg}
                            onChange={(event: React.ChangeEvent<{ value: string }>) => setCommitMsg(event.target.value)}
                            className="form-control" />
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label className="col-xl-2 col-sm-3 col-sm-2 col-form-label">Selet Timing</label>
                    <div className="col-xl-10 col-lg-9 col-sm-10">
                        <div className="n-chk">
                            <label className="new-control new-radio new-radio-text radio-primary mr-3" >
                                <input type="radio" onChange={() => setScheduledAt('morning')} className="new-control-input" name="custom-radio-4" checked={scheduledAt == 'morning'} />
                                <span className="new-control-indicator"></span><span className="new-radio-content ml-3">Morning</span>
                            </label>
                            <label className="new-control new-radio new-radio-text radio-primary mr-3" >
                                <input type="radio" onChange={() => setScheduledAt('evening')} className="new-control-input" name="custom-radio-4" checked={scheduledAt == 'evening'} />
                                <span className="new-control-indicator"></span><span className="new-radio-content ml-3">Evening</span>
                            </label>
                            <label className="new-control new-radio new-radio-text radio-primary mr-3" >
                                <input type="radio" onChange={() => setScheduledAt('night')} className="new-control-input" name="custom-radio-4" checked={scheduledAt == 'night'} />
                                <span className="new-control-indicator"></span><span className="new-radio-content ml-3">Night</span>
                            </label>
                            <label className="new-control new-radio new-radio-text radio-danger mr-3">
                                <input type="radio" onChange={() => setScheduledAt('now')} className="new-control-input" name="custom-radio-4" checked={scheduledAt == 'now'} />
                                <span className="new-control-indicator"></span><span className="new-radio-content ml-3">NOW</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group row w-100 text-right">
                    <div className="col-sm-12">
                        <button onClick={publishToHugo} className="btn btn-primary mt-3">PUBLISH</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
const PublishRoutes = () => {
    return (
        <Switch>
            <Route path="/publish/:id">
                <PublishItem />
            </Route>
            <Route exact path="/publish">
                <PublishLayout />
            </Route>
            <Redirect from="/publish/*" to="/publish" />
        </Switch>
    )
}



export default PublishRoutes;