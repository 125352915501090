import React from "react";
import {
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import { useQuery } from 'urql';
import { useParams } from "react-router-dom";
import keycloak from "./keycloak";
import jwt_decode from 'jwt-decode';
import User from './Pages/User';
import Mail from './Pages/Mail';
import Publish from './Pages/Publish';
import { JwtToken } from './types';

const checkUser = `
query ($id: String!) {
  user_by_pk(id: $id) {
    id
  }
}
`
const addUser = `
mutation ($id: String!,$name: String!,$role: String!,$email: String!){
  insert_user(objects: {id: $id, name: $name, role: $role, email: $email}) {
    returning {
      name
    }
  }
}
`
function App() {
  const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
  const [checkUserResult] = useQuery({
    query: checkUser,
    variables: { id: jwt.sub }
  });

  const [addUserResult] = useQuery({
    query: addUser,
    variables: {
      id: jwt.sub,
      name: jwt.name,
      email: jwt.email,
      role: jwt["https://hasura.io/jwt/claims"]["x-hasura-default-role"]
    },
    pause: (checkUserResult.fetching || checkUserResult.data['user_by_pk'] != null)
  });

  if (addUserResult.fetching) {
    return <div>Intialising User..</div>
  }
  if (addUserResult.error || checkUserResult.error) {
    return <div>Error in User auth</div>
  }

  return (
    <div>
      <nav>
        <ul>
        <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/publish">Publsih</Link>
          </li>
          <li>
            <Link to="/mail">Mail</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
        </ul>
      </nav>

      {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/mail">
          <Mail />
        </Route>
        <Route exact path="/users">
          <User />
        </Route>
        <Route path="/publish">
          <Publish />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Redirect from="/*" to="/users" />
      </Switch>
    </div>
  );
}

function Home() {
  return <h2>Home</h2>;
}

export default App;

