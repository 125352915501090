import React from "react";
import {
    Switch,
    Route,
    Link
} from "react-router-dom";

function NavBar() {
    return (
        <>
            <div className="sidebar-wrapper sidebar-theme">

                <nav id="compactSidebar">

                    <div className="theme-logo">
                        <Link to="/mail">
                            <img src="/assets/img/90x90.jpg" className="navbar-logo" alt="logo" />
                        </Link>
                    </div>

                    <ul className="menu-categories">
                        <li className="menu menu-single">
                            <Link to="/" data-active="false" className="menu-toggle">
                                <div className="base-menu">
                                    <div className="base-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                                    </div>
                                </div>
                            </Link>
                            <div className="tooltip"><span>Blank Page</span></div>
                        </li>

                        <li className="menu menu-single">
                            <Link to="/users" data-active="false" className="menu-toggle">
                                <div className="base-menu">
                                    <div className="base-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
                                    </div>
                                </div>
                            </Link>
                            <div className="tooltip"><span>Boxed</span></div>
                        </li>

                        <li className="menu menu-single">
                            <Link to="/" data-active="false" className="menu-toggle">
                                <div className="base-menu">
                                    <div className="base-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                    </div>
                                </div>
                            </Link>
                            <div className="tooltip"><span>Breadcrumb</span></div>
                        </li>

                        <li className="menu menu-single">
                            <Link to="/" data-active="false" className="menu-toggle">
                                <div className="base-menu">
                                    <div className="base-icons">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-folder"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
                                    </div>
                                </div>
                            </Link>
                            <div className="tooltip"><span>Single Click Menu</span></div>
                        </li>

                    </ul>

                    <div className="sidebar-bottom-actions">

                        <div className="external-links">
                            <Link to="/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                                <div className="tooltip"><span>Help</span></div>
                            </Link>
                        </div>

                        <div className="dropdown user-profile-dropdown">
                            <Link to="/" className="nav-link dropdown-toggle user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="/assets/img/90x90.jpg" className="img-fluid" alt="avatar" />
                            </Link>
                        </div>

                    </div>

                </nav>

                <div id="compact_submenuSidebar" className="submenu-sidebar">

                </div>

            </div>
        </>
    );
}


export default NavBar;
