import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'urql';
import ReactQuill from 'react-quill';
import { quillOptions } from '../Components/Editor';
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import {
    Switch,
    Route,
    Redirect,
    Link
} from "react-router-dom";
import jwt_decode from 'jwt-decode';
import keycloak from "../keycloak";
import { JwtToken, MessageItem, ContentBox, Tag, ApproveBox, MessageBoxStatus, Attachment } from '../types';
import Editor, { UnprivilegedEditor } from '../Components/Editor';
import TagsSelect from '../Components/TagsSelect';
import LightBox from '../Components/LightBox';
import Modal from '../Components/Modal';
import Accordion from '../Components/Accordion';
import FileUpload, { WrapperCss } from '../Components/FileUpload';
import { timeAgo } from '../index';

type MailState = 'newMail' | 'listMail' | 'showMail' | 'savedMail' | 'trashMail'
const getTagsQuery = `
query MyQuery {
    tags {
      name
      uuid
      color
    }
  }
`
const MailLayout = () => {
    let { id } = useParams<{ id: string }>();
    let history = useHistory();
    const [isToggle, setIsToggle] = React.useState<boolean>(false);
    const [state, setState] = React.useState<MailState>(id ? 'showMail' : 'listMail');
    const [getTagsResult, reexecuteQueryTags] = useQuery({ query: getTagsQuery });
    const { data, fetching, error } = getTagsResult;
    useEffect(() => {
        if (id !== undefined) {
            setState('showMail')
        } else {
            // setState('listMail')
        }
    }, [id])
    return (
        <>
            <div className="row layout-top-spacing">
                <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="row">
                        <div className="col-xl-12  col-md-12">
                            <div className="mail-box-container">
                                <div
                                    onClick={() => setIsToggle(!isToggle)}
                                    className={`mail-overlay ${isToggle ? 'mail-overlay-show' : ''}`}></div>
                                <div className={`tab-title ${isToggle ? 'mail-menu-show' : ''}`}>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12 text-center mail-btn-container">
                                            <div id="btn-compose-mail" onClick={() => { setState('newMail'); history.push(`/mail`) }} className="btn btn-block" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg></div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12 mail-categories-container">
                                            <div className="mail-sidebar-scroll">

                                                <ul className="nav nav-pills d-block" id="pills-tab" role="tablist">
                                                    {/* <li className="nav-item">
                                                        <a className="nav-link list-actions active" id="mailInbox"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-inbox"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg> <span className="nav-names">Inbox</span> <span className="mail-badge badge"></span></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link list-actions" id="important"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg> <span className="nav-names">Important</span></a>
                                                    </li> */}
                                                    <li onClick={() => { setState('listMail'); history.push(`/mail`) }} className="nav-item">
                                                        <a className={`nav-link list-actions ${state == 'listMail' ? 'active' : ''}`} id="sentmail"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg> <span className="nav-names"> Inbox/Sent</span></a>
                                                    </li>
                                                    <li onClick={() => { setState('savedMail'); history.push(`/mail`) }} className="nav-item">
                                                        <a className={`nav-link list-actions ${state == 'savedMail' ? 'active' : ''}`} id="draft"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg> <span className="nav-names">Saved</span> <span className="mail-badge badge"></span></a>
                                                    </li>
                                                    {/* <li className="nav-item">
                                                        <a className="nav-link list-actions" id="spam"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg> <span className="nav-names">Spam</span></a>
                                                    </li> */}
                                                    <li onClick={() => { setState('trashMail'); history.push(`/mail`) }} className="nav-item">
                                                        <a className={`nav-link list-actions ${state == 'trashMail' ? 'active' : ''}`} id="trashed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg> <span className="nav-names">Trash</span></a>
                                                    </li>
                                                </ul>

                                                <p className="group-section"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7" y2="7"></line></svg> Verticals</p>
                                                {
                                                    (fetching || error) ? (<p>loading..</p>) : (
                                                        <ul className="nav nav-pills d-block group-list" role="tablist">
                                                            {
                                                                data.tags.map((tag: Tag) => (
                                                                    <li key={tag.uuid} className="nav-item" style={{ position: 'relative' }}>
                                                                        <div style={{
                                                                            width: 10, height: 10, background: tag.color, display: 'block',
                                                                            position: 'absolute', top: 10, left: 25, borderRadius: '50%'
                                                                        }} />
                                                                        <a className="nav-link list-actions" ><span>{tag.name}</span></a>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="mailbox-inbox" className="mailbox-inbox">
                                    {(state == "listMail" || state == "trashMail" || state == "savedMail") &&
                                        <MessageBox
                                            limit={10}
                                            offset={0}
                                            state={state}
                                            onItemClick={(id: string) => history.push(`/mail/${id}`)}
                                            isToggle={isToggle}
                                            setIsToggle={setIsToggle}
                                        />
                                    }
                                    <div style={{ display: (state != "listMail" ? 'block' : 'none') }} className="content-box">
                                        {state == "newMail" &&
                                            <CreateMail
                                                id={uuid()}
                                                tagList={data.tags}
                                                onDone={() => {
                                                    setState('listMail'); history.push(`/mail`);
                                                }}
                                                onFailure={() => { }} />}
                                        {state == "showMail" &&
                                            <ShowMail id={id} onDone={() => {
                                                setState('listMail'); history.push(`/mail`);
                                            }} />}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </>)
}

const getMessageBoxQuery = `
query ($limit: Int!, $offset: Int!, $status: String!) {
    messageBox(offset: $offset, order_by: {timestamp: desc}, limit: $limit, where: {_and: {status: {_eq: $status}, contentBox: {isPublished: {_eq: false}}}}) {
      user {
        email
        name
      }
      isRead
      status
      shortMessage
      timestamp
      tag {
        name
        uuid
        color
      }
      title_ID
      contentBox {
        title
        isApproved
      }
    }
  }
`
type MessageBoxlProps = {
    limit: number,
    offset: number,
    state: MailState,
    onItemClick: Function,
    isToggle?: boolean,
    setIsToggle?: Function
}

const MessageBox = ({ limit, offset, onItemClick, state, isToggle, setIsToggle }: MessageBoxlProps) => {
    let status: MessageBoxStatus = 'inbox';
    switch (state) {
        case 'listMail': { status = 'inbox' }; break;
        case 'savedMail': { status = 'saved' }; break;
        case 'trashMail': { status = 'trash' }; break;
        default: { status = 'inbox' }; break;
    }
    const [getMessageBoxResult, reexecuteQueryMessageBox] = useQuery({
        query: getMessageBoxQuery,
        variables: {
            limit,
            offset,
            status,
        }
    });
    const { data, fetching, error } = getMessageBoxResult;
    const refresh = () => {
        // Refetch the query and skip the cache
        reexecuteQueryMessageBox({ requestPolicy: 'network-only' });
    };
    const MessageBoxHeader = () => {
        return (
            <>
                <div className="search">
                    <div onClick={() => setIsToggle && setIsToggle(!isToggle)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                    </div>
                    <input type="text" className="form-control input-search" placeholder="Search Here..." />
                </div>
                <div className="action-center">
                    <div />
                    <div onClick={refresh}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-ccw"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
                    </div>
                </div>
            </>
        )
    }
    useEffect(() => {
        refresh()
    }, [state, limit, offset])
    if (fetching || error) {
        return <MessageBoxHeader />
    }
    let results: MessageItem[] = data.messageBox;
    return (
        <>
            <MessageBoxHeader />
            <div className="message-box">
                {
                    <div className="message-box-scroll">
                        {
                            results.map((result: MessageItem) => <div onClick={() => onItemClick(result.title_ID)} key={result.title_ID}>
                                <MessageBoxItem item={result} />
                            </div>)
                        }
                    </div>
                }
            </div>
        </>
    )
}
const MessageBoxItem = ({ item }: { item: MessageItem }) => {
    return (
        <div data-id={item.title_ID} className="mail-item mailInbox">
            <div className="animated animatedFadeInUp fadeInUp">
                <div className="mb-0">
                    <div className={`mail-item-heading ${item.tag?.name} collapsed`} data-toggle="collapse" role="navigation" aria-expanded="false">
                        <div className="mail-item-inner">
                            <div className="d-flex">
                                {/* <div className="n-chk text-center">
                                        <label className="new-control new-checkbox checkbox-primary">
                                            <input type="checkbox" className="new-control-input inbox-chkbox" />
                                            <span className="new-control-indicator"></span>
                                        </label>
                                    </div> */}
                                <div className="f-head">
                                    <img src={item.user?.picture || "/assets/img/90x90.jpg"} className="user-profile" alt="avatar" />
                                </div>
                                <div className="f-body">
                                    <div className="meta-mail-time">
                                        <p className="user-email">{item.user?.name}</p>
                                    </div>
                                    <div className="meta-title-tag">
                                        <p className="mail-content-excerpt" >
                                            <span className="mail-title">
                                                {item.contentBox?.title} -
                                            </span>
                                            {item.shortMessage}
                                        </p>
                                        <div className="tags">
                                            <div style={{
                                                width: 10, height: 10, background: item.tag?.color, display: 'block',
                                                position: 'absolute', top: 10, left: 25, borderRadius: '50%'
                                            }} />
                                        </div>
                                        <p className="meta-time align-self-center">{timeAgo.format(new Date(item.timestamp), 'round-minute')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const insertContentBoxQuery = `
mutation MyMutation($object: contentBox_insert_input!) {
    insert_contentBox_one(object: $object){
      uuid
    }
  }
`
type CreateMailProps = {
    id: string,
    tagList: Tag[],
    onDone: Function,
    onFailure: Function
}

const CreateMail = ({ tagList, id, onDone, onFailure }: CreateMailProps) => {
    const [content, setContent] = React.useState<string>('');
    const [html, setHtml] = React.useState<string>('');
    const [tag, setTag] = React.useState<Tag>(tagList[0]);
    const [title, setTitle] = React.useState<string>('');
    const [files, setFiles] = React.useState<Attachment[]>([]);
    const onEditorStateChange = (editorRef: UnprivilegedEditor, text: string, htmlData: string) => {
        setContent(editorRef.getText());
        setHtml(htmlData)
    };
    const [insertContentBoxResult, insertContentBox] = useMutation(insertContentBoxQuery);
    const submit = async () => {
        const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
        let timeStamp = new Date().toISOString();
        let result = await insertContentBox({
            object: {
                author_ID: jwt.sub,
                tag_ID: tag?.uuid,
                uuid: id,
                title: title,
                content: content,
                html: html,
                timestamp: timeStamp,
                childof: null,
                attachments: {
                    data: files
                },
                messageBoxes: {
                    data: {
                        author_ID: jwt.sub,
                        tag_ID: tag?.uuid,
                        isRead: false,
                        timestamp: timeStamp,
                        shortMessage: content.substring(0, 70)
                    }
                }
            }
        })
        if (result) {
            onDone()
        } else {
            onFailure()
        }
        console.log(result);
    }
    return (
        <>
            <div className="d-flex msg-close">
                <div onClick={() => onDone()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left close-message"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                </div>
                <h2 className="mail-title">
                    New Article
                </h2>
            </div>
            <div style={{ padding: 20 }}>
                <div className="row">
                    <div className="col-xl-6  col-md-6">
                        <input id="t-text-new-article" type="text"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => setTitle(event.target.value)}
                            name="article-title-txt" placeholder="Article title.."
                            value={title} className="form-control" />
                    </div>
                    <div className="col-xl-6  col-md-6"><TagsSelect onChange={(data: Tag) => setTag(data)} /></div>
                </div>
                <Editor onEditorStateChange={onEditorStateChange}
                    uploadURl={`mailBox/${id}/temp/`}
                    onChangeImg={(imgList: Attachment[]) => {
                        console.log(imgList)
                        setFiles(imgList)
                    }}
                />

                <div style={{ marginTop: 50 }} className="row">
                    <div className="col-xl-10  col-md-6 d-flex">
                        <div style={WrapperCss} className="gallery text-center d-flex justify-content-center align-items-center">
                            <LightBox data={files} />
                        </div>
                    </div>
                    <div className="col-xl-2  col-md-6 ">
                        <button onClick={submit} className="btn btn-primary mt-3 mb-2 mr-2">Submit</button>
                        <button className="btn btn-outline-secondary mt-3 mb-2 ml-2">Save for Later</button>
                    </div>
                </div>
            </div>
        </>
    )
}
const ShowMailQuery = `
query ($uuid: String!) {
    contentBox(where: {_or: [{childof: {_eq: $uuid}}, {uuid: {_eq: $uuid}}]}, order_by: {timestamp: asc_nulls_first}) {
      uuid
      childof
      html
      content
      timestamp
      title
      isApproved
      tag {
        name
        color
        uuid
      }
      user {
        email
        name
      }
      attachments {
        uuid
        fileName
        fileURL
        previewURL
      }
      approveCommentBoxes(order_by: {timestamp: asc}) {
        comment
        timestamp
        uuid
        user {
          name
          email
          picture
        }
        approvedBox {
          html
          tag{
            color
            name
            uuid
          }
          contentBox{
            uuid
          }
          content
          shortContent
          title
          uuid
          imageBoxes {
            fileURL
            name
            previewURL
            uuid
          }
        }
      }
      publishCommentBoxes(order_by: {timestamp: asc}) {
        comment
        timestamp
        uuid
        user {
          name
          email
          picture
        }
      }
    }
  }
`
const ShowMail = ({ id, onDone }: { id: string, onDone: Function }) => {
    const [content, setContent] = React.useState<string>('');
    const [doReply, setDoReply] = React.useState<boolean>(false);
    const [doApprove, setDoApprove] = React.useState<boolean>(false);
    const [html, setHtml] = React.useState<string>('');
    const [files, setFiles] = React.useState<Attachment[]>([]);
    const [getContentBoxResult, reexecuteQueryContentBox] = useQuery({ query: ShowMailQuery, variables: { uuid: id } });
    const [insertContentBoxResult, insertContentBox] = useMutation(insertContentBoxQuery);
    const { data, fetching, error } = getContentBoxResult;
    const onEditorStateChange = (editorRef: UnprivilegedEditor, text: string, htmlData: string) => {
        setContent(editorRef.getText());
        setHtml(htmlData)
    };
    useEffect(() => {
        reexecuteQueryContentBox({ requestPolicy: 'network-only' });
    }, [id])
    useEffect(() => {
        if (data) {
            let results = data.contentBox as ContentBox[]
            setContent(results[results.length - 1].content || '');
            setHtml(results[results.length - 1].html)
        }
    }, [data])
    if (fetching || error) {
        return <p>Loading...</p>
    }
    let results = data.contentBox as ContentBox[]
    const submit = async () => {
        let newId = uuid();
        const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
        let timeStamp = new Date().toISOString();
        let result = await insertContentBox({
            object: {
                author_ID: jwt.sub,
                tag_ID: results[0].tag.uuid,
                uuid: newId,
                title: results[0].title,
                content: content,
                html: html,
                timestamp: timeStamp,
                childof: id,
                attachments: {
                    data: files
                }
            }
        })
        if (result) {
            setDoReply(false);
            reexecuteQueryContentBox({ requestPolicy: 'network-only' });
        } else {

        }
    }
    let prefillData, lastIndex: number;
    if (results[0]?.approveCommentBoxes) {
        lastIndex = results[0]?.approveCommentBoxes?.length - 1;
        prefillData = results[0]?.approveCommentBoxes[lastIndex]?.approvedBox;
    }
    return (
        <>
            <div className="d-flex msg-close">
                <div onClick={() => onDone()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left close-message"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                </div>
                <h2 className="mail-title">
                    {results[0].title}
                </h2>
            </div>
            <>
                {results.map(item => <div key={item.uuid}><ShowMailItem item={item} /></div>)}
            </>
            <div className="mail-content-container">
                {
                    results[0]?.approveCommentBoxes?.length ?
                        <ul className="list-unstyled">
                            {
                                results[0]?.approveCommentBoxes?.map((approvedComment, index) => (
                                    <li key={approvedComment.uuid} className="media">
                                        <img className="rounded"
                                            src={approvedComment?.user?.picture || "/assets/img/90x90.jpg"}
                                            alt={approvedComment?.user?.name} />
                                        <div className="media-body">
                                            <h4 className="media-heading ">
                                                <span className="media-title mr-2"> {approvedComment?.user?.name}</span>
                                                <span className="badge badge-info">Approved</span>
                                            </h4>
                                            <p className="media-text"> {new Date(approvedComment.timestamp).toLocaleString()}</p>
                                            <p className="mb-2 media-text"> {approvedComment.comment}</p>
                                            {index == lastIndex && (
                                                <>
                                                    <p className="media-text">
                                                        <span style={{ backgroundColor: approvedComment.approvedBox?.tag?.color }} className="mr-2 badge badge-info">{approvedComment.approvedBox?.tag?.name}</span>
                                                        {approvedComment.approvedBox?.title}
                                                    </p>
                                                    <p className="media-text">
                                                        {approvedComment.approvedBox?.shortContent}
                                                    </p>
                                                    <div className="mail-content mt-0 pt-0">
                                                        <p dangerouslySetInnerHTML={{ __html: approvedComment.approvedBox?.html || 'ERROR' }} />
                                                        {approvedComment.approvedBox?.imageBoxes && <div className="attachments">
                                                            <div style={WrapperCss} className="gallery text-center d-flex justify-content-center align-items-center">
                                                                <LightBox data={approvedComment.approvedBox?.imageBoxes.map(item => ({
                                                                    fileName: item.name,
                                                                    uuid: item.uuid,
                                                                    fileURL: item.fileURL,
                                                                    previewURL: item.fileURL
                                                                }))} />
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </li>
                                ))
                            }
                        </ul> : (<></>)
                }
                {
                    results[0]?.publishCommentBoxes?.length ?
                        <ul className="list-unstyled">
                            {
                                results[0]?.publishCommentBoxes?.map((publishedComment) => (
                                    <li className="media">
                                        <img className="align-self-center rounded"
                                            src={publishedComment.user.picture || "/assets/img/90x90.jpg"}
                                            alt={publishedComment.user.name} />
                                        <div className="media-body">
                                            <h4 className="media-heading">
                                                <span className="media-title mr-2"> {publishedComment.user.name}</span>
                                                <span className="badge badge-primary">Published</span>
                                            </h4>
                                            <p className="media-text"> {new Date(publishedComment.timestamp).toLocaleString()}</p>
                                            <p className="media-text"> {publishedComment.comment}</p>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul> : (<></>)
                }
                {
                    (results[0].isPublished && !results[0].isApproved) &&
                    <div className="alert alert-arrow-left alert-icon-left alert-light-danger mb-4" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12" y2="17"></line></svg>
                        <strong>Warning!</strong> The current article is Published, edit with care.
                    </div>
                }
                {
                    results[0].isApproved &&
                    <div className="alert alert-arrow-left alert-icon-left alert-light-info mb-4" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                        <strong>Info</strong> The current article has been locked for Publishing,to edit request publisher.
                    </div>
                }
                {
                    !doReply && !results[0].isApproved &&
                    <div className="d-flex flex-row justify-content-between align-items-center mb-5">
                        <button onClick={() => setDoReply(true)} className="btn btn-outline-secondary mt-3 mb-2 ml-3">Reply</button>
                        <button onClick={() => setDoApprove(true)} className="btn btn-primary mt-3 mb-2 mr-3">Approve</button>
                    </div>
                }
                {
                    doReply &&
                    <div style={{ padding: 20 }}>
                        <Editor
                            intialValue={html}
                            onEditorStateChange={onEditorStateChange}
                            uploadURl={`mailBox/${id}/temp/`}
                            onChangeImg={(imgList: Attachment[]) => setFiles(imgList)}
                        />
                        <div style={{ marginTop: 50 }} className="row">
                            <div className="col-xl-10  col-md-6">
                                <div style={WrapperCss} className="gallery text-center mt-5 mb-2 pt-2 pb-2 d-flex flex-row align-items-center">
                                    {files.length > 0 ?
                                        <LightBox data={files} size="sm" />
                                        : <></>}
                                </div>
                            </div>
                            <div className="col-xl-2  col-md-6 ">
                                <button onClick={submit} className="btn btn-primary mt-3 mb-2 mr-2">Reply</button>
                                <button onClick={() => setDoReply(false)} className="btn btn-outline-secondary mt-3 mb-2 ml-2">Cancel</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ApproveModal
                contents={results}
                prefillData={prefillData}
                doApprove={doApprove}
                setDoApprove={setDoApprove}
            />
        </>
    )
}

type ApproveModalProps = {
    contents: ContentBox[],
    prefillData: ApproveBox | undefined,
    setDoApprove: Function,
    doApprove: boolean
}
const insertToApproveBoxQuery = `
mutation ($content: String!, $html: String!, $lastApprovedTime: timestamptz!, $shortContent: String!, $title: String!, $timestamp: timestamptz!, $tag_ID: String!, $uuid: String!, $contentBox_ID: String!, $imageBoxes: [imageBox_insert_input!]!, $approveCommentBoxes: [approveCommentBox_insert_input!]!) {
    insert_approvedBox_one(object: {content: $content, contentBox_ID: $contentBox_ID, html: $html, lastApprovedTime: $lastApprovedTime, shortContent: $shortContent, timestamp: $timestamp, tag_ID: $tag_ID, uuid: $uuid, imageBoxes: {data: $imageBoxes}, approveCommentBoxes: {data: $approveCommentBoxes}, title: $title}) {
      uuid
      lastApprovedTime
    }
    update_contentBox_by_pk(pk_columns: {uuid: $contentBox_ID}, _set: {isApproved: true}) {
      isApproved
    }
  }
`;

const updateApproveBoxByID_updateContentBoxByID_addApproveCommentBoxByIDQuerry = `
mutation ($approveBoxID: String!, $contentBoxID: String!, $approvedBy_ID: String!, $imageBoxes: [imageBox_insert_input!]!, $content: String!, $comment: String!, $approveCommentBoxID: String!, $html: String!, $lastApprovedTime: timestamptz!, $timestamp: timestamptz!, $shortContent: String!, $tag_ID: String!, $title: String!) {
    update_approvedBox_by_pk(pk_columns: {uuid: $approveBoxID}, _set: {content: $content, html: $html, lastApprovedTime: $lastApprovedTime, shortContent: $shortContent, tag_ID: $tag_ID, title: $title}) {
      lastApprovedTime
      uuid
    }
    update_contentBox_by_pk(pk_columns: {uuid: $contentBoxID}, _set: {isApproved: true}) {
      isApproved
    }
    insert_approveCommentBox_one(object: {approveBox_ID: $approveBoxID, contentBox_ID: $contentBoxID, comment: $comment, uuid: $approveCommentBoxID, timestamp: $timestamp, approvedBy_ID: $approvedBy_ID}) {
      uuid
    }
    delete_imageBox(where: {attachment_ID: {_eq: $approveBoxID}}) {
      affected_rows
    }
    insert_imageBox(objects: $imageBoxes) {
      affected_rows
    }
  }  
`

const ApproveModal = ({ contents, doApprove, setDoApprove, prefillData }: ApproveModalProps) => {
    const [finalContent, setFinalContent] = React.useState<string>(prefillData?.content || '');
    const [finalHtml, setFinalHtml] = React.useState<string>(prefillData?.html || '');
    const [approveBoxID, setApproveBoxID] = React.useState<string>(prefillData?.uuid || uuid());
    const [coppyHTML, setCoppyHTML] = React.useState<string>('');
    const [shortContent, setShortContent] = React.useState<string>(prefillData?.shortContent || '');
    const [approveMessage, setApproveMessage] = React.useState<string>('I approve this article');
    const [tag, setTag] = React.useState<Tag>(prefillData?.tag || contents[0].tag);
    const [title, setTitle] = React.useState<string>(prefillData?.title || contents[0].title);
    const [files, setFiles] = React.useState<Attachment[]>(prefillData?.imageBoxes?.map(item => ({
        fileName: item.name,
        fileURL: item.fileURL,
        previewURL: item.fileURL,
        uuid: item.uuid
    })) || []);
    const [extraFiles, setExtraFiles] = React.useState<Attachment[]>([])
    const [insertApproveBoxResult, insertApproveBox] = useMutation(insertToApproveBoxQuery);
    const [updateApproveBoxResult, updateApproveBox] = useMutation(updateApproveBoxByID_updateContentBoxByID_addApproveCommentBoxByIDQuerry)
    // @ts-ignore
    const allImageFiles: Attachment[] = [...new Map([...files, ...extraFiles].map(item => [item['uuid'], item])).values()]
    const approve = async () => {
        //COPPY images to approve folder
        const uploadResponse = await fetch("https://api.nivendha.me/coppyFiles/", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "sourceImages": allImageFiles.map((file => {
                    let filePathWithUUID = file.fileURL.split('.appspot.com/')[1];
                    let filePathSplitUUID = filePathWithUUID.split('#');
                    return filePathSplitUUID[0]
                })),
                "destinationURL": allImageFiles.map(file => {
                    let filePathSplitUUID = file.fileName.split('#');
                    return `imageBox/${approveBoxID}/${filePathSplitUUID[0]}#${file.uuid}`
                })
            })
        }).then(response => response.json())
            .catch(error => console.log('coppy_error', error));
        if (prefillData) {
            // UPDATE APPROVE
            const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
            let timeStamp = new Date().toISOString();
            const result = await updateApproveBox({
                approveBoxID: approveBoxID,
                contentBoxID: prefillData.contentBox?.uuid,
                approvedBy_ID: jwt.sub,
                comment: approveMessage,
                approveCommentBoxID: uuid(),
                html: finalHtml,
                title: title,
                tag_ID: tag.uuid,
                timestamp: timeStamp,
                content: finalContent,
                shortContent: shortContent,
                lastApprovedTime: timeStamp,
                imageBoxes: uploadResponse.data?.map((data: any) => ({
                    ...data,
                    attachment_ID: approveBoxID,
                    createdAt: timeStamp,
                    updatedAt: timeStamp
                }))
            })
            if (result) {
                setDoApprove(false);
            } else {
                // show error notification
            }
        } else {
            // NEW ARTICLE APPROVE
            let newId = approveBoxID
            const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
            let timeStamp = new Date().toISOString();
            const approveBoxData: ApproveBox = {
                tag_ID: tag.uuid,
                uuid: newId,
                title: title,
                html: finalHtml,
                timestamp: timeStamp,
                lastApprovedTime: timeStamp,
                content: finalContent,
                shortContent: shortContent,
                contentBox_ID: contents[0].uuid,
                approveCommentBoxes: [{
                    approvedBy_ID: jwt.sub,
                    contentBox_ID: contents[0].uuid,
                    comment: approveMessage,
                    uuid: uuid(),
                    timestamp: timeStamp,
                }],
                imageBoxes: uploadResponse.data?.map((data: any) => ({
                    ...data,
                    createdAt: timeStamp,
                    updatedAt: timeStamp
                }))
            }
            let result = await insertApproveBox(approveBoxData)
            if (result) {
                setDoApprove(false);
            } else {
                // show error notification
            }
        }

    }
    return (
        <>
            <Modal open={doApprove}
                fullWidth={true}
                title={`Approve - ${contents[0].title}`}
                onSubmit={() => approve()}
                onSubmitText={prefillData ? 'Update' : 'Submit'}
                onClose={() => {
                    setFiles(prefillData?.imageBoxes?.map(item => ({
                        fileName: item.name,
                        fileURL: item.fileURL,
                        previewURL: item.fileURL,
                        uuid: item.uuid
                    })) || [])
                    setExtraFiles([])
                    setFinalHtml(prefillData?.html || '');
                    setFinalContent(prefillData?.content || '')
                    setDoApprove(false)
                }}>
                <div className="row">
                    <div className="col-xl-6  col-md-6">
                        <Accordion
                            id={"approve_" + contents[0].uuid}
                            data={contents.map(result => ({
                                key: result.uuid,
                                body: (<>
                                    <div dangerouslySetInnerHTML={{ __html: result.html }} />
                                    {result.attachments && <div className="attachments">
                                        <div className="gallery text-center d-flex justify-content-center align-items-center">
                                            <LightBox data={result.attachments}
                                                maxSelection={5}
                                                selectedFiles={extraFiles}
                                                mandatoryFiles={files}
                                                onSelect={(fileSelected: Attachment[]) => {
                                                    setExtraFiles(fileSelected)
                                                }} />
                                        </div>
                                    </div>}
                                </>),
                                title: (<>
                                    <div className="mailbox-inbox">
                                        <div data-id={result.uuid} className="mail-content-container mailInbox" >
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex user-info">
                                                    <div className="f-head">
                                                        <img src={result.user?.picture || "/assets/img/90x90.jpg"} className="user-profile" alt="avatar" />
                                                    </div>
                                                    <div className="f-body">
                                                        <div className="meta-title-tag">
                                                            <h4 className="mail-usr-name">{result.user?.name}</h4>
                                                        </div>
                                                        <div className="meta-mail-time">
                                                            <p className="user-email" >{result.user?.email}</p>
                                                            <p className="mail-content-meta-date current-recent-mail">-</p>
                                                            <p className="meta-time align-self-center">{new Date(result.timestamp).toLocaleString()}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div onClick={() => setCoppyHTML(finalHtml + result.html)}>
                                                    <span style={{ color: "#4361ee" }}>Add</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })).reverse()}
                        />
                    </div>
                    <div className="col-xl-6  col-md-6">
                        <input id="t-text-approve-article" type="text"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => setTitle(event.target.value)}
                            name="article-title-txt" placeholder="Article title.."
                            value={title} className="form-control mb-2" />
                        <TagsSelect initialValue={contents[0].tag} onChange={(data: Tag) => setTag(data)} />
                        <Editor
                            intialValue={finalHtml}
                            copyValue={coppyHTML}
                            onCoppySuccess={() => setCoppyHTML('')}
                            onEditorStateChange={(editor: UnprivilegedEditor, source: string, html: string) => {
                                setFinalHtml(html);
                                setFinalContent(editor.getText())
                            }}
                            uploadURl={`mailBox/${contents[0].uuid}/temp/`}
                            onChangeImg={(files: Attachment[]) => setFiles(files)}
                        />
                        <div style={WrapperCss} className="gallery text-center mt-5 mb-2 pt-2 pb-2 d-flex flex-row align-items-center">
                            {files.length > 0 ?
                                <LightBox
                                    data={allImageFiles}
                                    selectedFiles={extraFiles}
                                    mandatoryFiles={files}
                                    onSelect={(fileSelected: Attachment[]) => {
                                        setExtraFiles(fileSelected)
                                    }}
                                    size="sm" />
                                : <h6 className={'pl-2'}>Please add atleast 1 image (max:5)</h6>}
                        </div>
                        <input type="text"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => setShortContent(event.target.value)}
                            name="article-title-txt" placeholder="Add description... (max:120 words)"
                            value={shortContent} className="form-control mb-2" />
                        <input type="text"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => setApproveMessage(event.target.value)}
                            name="article-title-txt" placeholder="Enter approver comment..."
                            value={approveMessage} className="form-control mb-2" />
                    </div>
                </div>
            </Modal>
        </>
    )
}
const ShowMailItem = ({ item }: { item: ContentBox }) => {
    return (
        <>
            <div data-id={item.uuid} className="mail-content-container mailInbox" >
                <div className="d-flex justify-content-between">
                    <div className="d-flex user-info">
                        <div className="f-head">
                            <img src={item.user?.picture || "/assets/img/90x90.jpg"} className="user-profile" alt="avatar" />
                        </div>
                        <div className="f-body">
                            <div className="meta-title-tag">
                                <h4 className="mail-usr-name">{item.user?.name}</h4>
                            </div>
                            <div className="meta-mail-time">
                                <p className="user-email" >{item.user?.email}</p>
                                <p className="mail-content-meta-date current-recent-mail">-</p>
                                <p className="meta-time align-self-center">{new Date(item.timestamp).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="action-btns">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-up-left reply"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-up-right forward"><polyline points="15 14 20 9 15 4"></polyline><path d="M4 20v-7a4 4 0 0 1 4-4h12"></path></svg>
                    </div> */}
                </div>

                <div className="mail-content">

                    <p dangerouslySetInnerHTML={{ __html: item.html }} />
                    {item.attachments && item.attachments.length > 0 && <div className="attachments">
                        <div style={WrapperCss} className="gallery text-center d-flex justify-content-center align-items-center">
                            <LightBox data={item.attachments} />
                        </div>
                    </div>}
                </div>




            </div>
        </>
    )
}

const MailRoutes = () => {
    return (
        <Switch>
            <Route path="/mail/:id">
                <MailLayout />
            </Route>
            <Route exact path="/mail">
                <MailLayout />
            </Route>
            <Redirect from="/mail/*" to="/mail" />
        </Switch>
    )
}



export default MailRoutes;