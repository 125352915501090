import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Layouts/main';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { createClient, Provider } from 'urql';
import './common.style.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import keycloak from './keycloak';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
export const timeAgo = new TimeAgo('en-US');

const client = createClient({
  url: 'https://test.nivendha.me/v1/graphql',
  fetchOptions: () => {
    const token = keycloak.idToken;
    return {
      headers: { authorization: `Bearer ${token}` },
    };
  },
});

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
  if (event == "onReady") {
    if (!keycloak.authenticated) {
      keycloak.login()
    }
  }
  if (event == "onAuthSuccess") {

  }
}

const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens)
}

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      LoadingComponent={<p>Keycloak Loading...</p>}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <Provider value={client}>
        <Main />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
)