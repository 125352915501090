import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: 'https://key.nivendha.me/auth',
  realm: 'master',
  clientId: 'hasura',
});

export default keycloak;