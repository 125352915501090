import React from 'react';
import './PhoneView.css';

type PhoneViewProps = {
    html: string
}
export default ({ html }: PhoneViewProps) => {

    const [width, setWidth] = React.useState<number>(400);
    const [height, setHeight] = React.useState<number>(650);
    const [view, setView] = React.useState<string>('3');
    const [isPrespective, setPrespective] = React.useState<boolean>(true);
    return (
        <div style={{ position: 'relative' }}>
            <div id="Phone_controls">
                <div>
                    <label >Width:</label>
                    <input type="number" id="iframeWidth"
                        onChange={(event: React.ChangeEvent<{ value: string }>) => setWidth(parseInt(event.target.value))}
                        placeholder="400" value={width} />
                </div>
                <div>
                    <label>Height:</label>
                    <input type="number" id="iframeHeight"
                        onChange={(event: React.ChangeEvent<{ value: string }>) => setHeight(parseInt(event.target.value))}
                        placeholder="650" value={height} />
                </div>
                <div>
                    <label >Add perspective:</label>
                    <input type="checkbox" id="iframePerspective"
                        onChange={() => setPrespective(!isPrespective)}
                        checked={isPrespective} />
                </div>
            </div>
            <div id="phone_views">
                <button onClick={() => setView('1')} >View 1 - Laying</button>
                <button onClick={() => setView('2')} >View 2 - Side</button>
                <button onClick={() => setView('3')} >View 3 - Front</button>
            </div>
            <div id="Phone_wrapper" style={{ perspective: isPrespective ? "1000px" : "none" }}>
                <div className={`phone view_${view}`}
                    style={{ width: width, height: height }}>
                    <iframe srcDoc={html} id="frame_1"></iframe>
                </div>
            </div>
        </div>
    )
};