import React from "react";
import { ImageBox } from '../types'
// @ts-ignore
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
type ImgSelectorItemProps = {
    data: ImageBox[],
    onChange: Function
}

export default ({ data, onChange }: ImgSelectorItemProps) => {
    const [selected, setSelected] = React.useState<ImageBox>(data[0]);
    const [cropedImg, setCropedImg] = React.useState<ImageBox | null>(null);
    const [doShowPreview, setDoShowPreview] = React.useState<Boolean>(false);
    const [bas64Selected, setBas64Selected] = React.useState<string>('');
    const imageList = data.map(dt => ({ url: dt.fileURL, title: dt.name }))
    const [imageIndex, setImageIndex] = React.useState<number | null>(null);
    const cropperRef = React.useRef<HTMLImageElement>(null);

    const onUpdate = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        setBas64Selected(cropper.getCroppedCanvas().toDataURL() as string)
    };
    
    const getBase64FromUrl = async () => {
        setBas64Selected('')
        const img64 = await fetch("https://api.nivendha.me/build/img", {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                url: selected.fileURL
            })
        }).then(response => response.text())
            .catch(error => console.log('build_img_error', error));
        setBas64Selected('data:image/png;base64,' + img64 as string)
    }
    React.useEffect(() => {
        getBase64FromUrl()
    }, [])
    return (
        <div>
            <div className="d-flex">
                {
                    data.map((file, index) => {
                        return (
                            <div key={file.uuid} className="d-flex justify-content-center align-items-center flex-column-reverse">
                                <span className="n-chk ml-4 mt-3">
                                    <label className="new-control new-checkbox new-checkbox-rounded checkbox-primary">
                                        <input type="checkbox" className="new-control-input"
                                            checked={selected.uuid == file.uuid}
                                            onChange={(event: React.ChangeEvent<{ checked: boolean }>) => {
                                                setSelected(file)
                                            }} />
                                        <span className="new-control-indicator" />
                                    </label>
                                </span>
                                <img
                                    onClick={() => setImageIndex(index)}
                                    src={file.fileURL || "/assets/img/350x250.jpg"}
                                    className={`img-fluid ml-4 mt-4 mb-4'}`}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: 10,
                                        width: 100, height: 75
                                    }} />
                            </div>
                        )
                    })
                }
                {imageIndex !== null && <Lightbox startIndex={imageIndex} images={imageList} onClose={() => setImageIndex(null)} />}
                <div className="form-group row w-100 text-right">
                    <div className="col-sm-12">
                        <button onClick={getBase64FromUrl} className="btn btn-primary mt-3">Load/Reset image</button>
                        <button onClick={onUpdate} className="btn btn-primary ml-3 mt-3">Crop image</button>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <Cropper
                    src={bas64Selected}
                    checkCrossOrigin={false}
                    style={{ width: '100%' }}
                    // minCropBoxWidth={828}
                    // minCropBoxHeight={1000}
                    // Cropper.js options
                    initialAspectRatio={16 / 9}
                    guides={false}
                    ref={cropperRef}
                />
            </div>
            <div style={{ position: 'absolute', bottom: 30, paddingRight: 30 }}
                className="d-flex w-100 justify-content-between align-items-center">
                {bas64Selected && <img
                    onClick={() => setDoShowPreview(true)}
                    src={bas64Selected}
                    className={`img-fluid ml-4 mt-4 mb-4'}`}
                    style={{
                        cursor: 'pointer',
                        borderRadius: 10,
                        width: 100, height: 75
                    }} />}
                <div className="text-right">
                    <button onClick={()=>onChange(bas64Selected)} className="btn btn-primary ml-3 mt-3">Preview image</button>
                </div>
            </div>
            {doShowPreview && <Lightbox images={[{
                url: bas64Selected,
                title: selected.name
            }]}
                onClose={() => setDoShowPreview(false)} />}
        </div>
    )
}
