import React, { useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

type ModalProps = {
    header?: React.ReactElement,
    title?: string,
    footer?: React.ReactElement,
    children: React.ReactElement,
    styles?: {
        root?: React.CSSProperties;
        overlay?: React.CSSProperties;
        modalContainer?: React.CSSProperties;
        modal?: React.CSSProperties;
        closeButton?: React.CSSProperties;
        closeIcon?: React.CSSProperties;
    },
    classNames?: {
        root?: string;
        overlay?: string;
        overlayAnimationIn?: string;
        overlayAnimationOut?: string;
        modalContainer?: string;
        modal?: string;
        modalAnimationIn?: string;
        modalAnimationOut?: string;
        closeButton?: string;
        closeIcon?: string;
    };
    fullWidth?: boolean,
    open: boolean,
    onClose: Function,
    onSubmit: Function,
    onCloseText?: string,
    onSubmitText?: string
}


export default ({ open, onClose, header, title, footer, fullWidth, children, styles, classNames, onSubmit, onCloseText, onSubmitText }: ModalProps) => {
    const [modalOpen, setModalOpen] = React.useState(open);
    useEffect(() => {
        setModalOpen(open)
    }, [open, modalOpen])
    const closeModal = () => {
        setModalOpen(false)
        onClose();
    }
    const submitModal = () => {
        setModalOpen(false)
        onSubmit();
    }
    return (
        <>
            <Modal
                open={modalOpen} onClose={closeModal}
                closeIcon={<></>}
                styles={{
                    // root: {
                    //     marginTop: 50,
                    //     marginBottom: 80
                    // },
                    modalContainer: {
                        overflow: 'hidden',
                        maxWidth: fullWidth ? '90%' : ''
                    },
                    modal: {
                        maxWidth: fullWidth ? '100%' : ''
                    },
                    ...styles
                }}
                classNames={{
                    root: '',
                    modalContainer: 'modal-dialog',
                    modal: 'modal-content',
                    overlay: '',
                    ...classNames
                }} >
                {
                    header ||
                    (<div className="modal-header">
                        <h5 className="modal-title" >{title}</h5>
                        <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </button>
                    </div>)
                }
                <div className={"modal-body"}
                // style={{ height: "calc(80vh - 75px)" }}
                >
                    <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '100%' }}>
                        {children}
                    </div>
                </div>
                {
                    footer ||
                    <div className="modal-footer">
                        <button className="btn" onClick={closeModal} data-dismiss="modal"><i className="flaticon-cancel-12"></i> {onCloseText || 'Cancel'}</button>
                        <button type="button" onClick={submitModal} className="btn btn-primary">{onSubmitText || 'Done'}</button>
                    </div>
                }
            </Modal>
        </>
    )
}