import * as React from 'react';
import { useQuery, useMutation } from 'urql';
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { JwtToken, User } from '../types'
import keycloak from "../keycloak";

const GetUser = `
query ($id: String!) {
  user_by_pk(id: $id) {
    id
    name
    email
    role
    bio
    picture
    phoneNumber
  }
}
`
const SetUser = `
mutation MyMutation($bio: String!, $email: String!, $phoneNumber: numeric!, $picture: bytea = null, $id: String!) {
    update_user_by_pk(pk_columns: {id: $id}, _set: {email: $email, bio: $bio, phoneNumber: $phoneNumber, picture: $picture}) {
      id
    }
  }  
`

const getAllUser = `
query {
    user {
      bio
      email
      id
      name
      phoneNumber
      picture
      role
    }
  }
`
const UserForm = ({ data, useListResult }: { data: User, useListResult: User[] }) => {

    // let { id } = useParams();

    const [doEdit, setDoEdit] = useState<boolean>(false);
    const [newData, setNewData] = useState<User>(data);
    const [updateUserResult, updateUser] = useMutation(SetUser);

    // const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
    // const [useResult, reexecuteQueryUser] = useQuery({ query: GetUser, variables: { id: jwt.sub } });
    // const [useListResult] = useQuery({ query: getAllUser });

    // const { data, fetching, error } = useResult;
    // if (fetching || useListResult.fetching) {
    //     return <div>Loading...</div>
    // }
    // let userData = data.user_by_pk;
    const updateBtn = async () => {
        let result = await updateUser({
            bio: newData.bio,
            email: newData.email,
            phoneNumber: newData.phoneNumber,
            picture: newData.picture || null,
            id: newData.id
        })
        setDoEdit(false);
        console.log(result);
    }
    useEffect(() => {
        if (data) {
            setNewData(data)
        }
    }, [data])

    return (
        <>
            <div className="row layout-spacing">
                <div className="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
                    <div className="user-profile layout-spacing">
                        <div className="widget-content widget-content-area">
                            <div className="d-flex justify-content-between">
                                <h3 className="">Info</h3>
                                {!doEdit ? <div onClick={() => setDoEdit(true)} className="mt-2 edit-profile">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                </div> : <button onClick={() => updateBtn()} className="btn btn-info mb-2">Save</button>
                                }
                            </div>
                            <div className="text-center user-info">
                                <img src={newData.picture || "/assets/img/90x90.jpg"} alt="avatar" />
                                <p className="">{newData.name}</p>
                            </div> <div className="user-info-list">
                                <div className=""> <ul className="contacts-block list-unstyled">
                                    <li className="contacts-block__item">
                                        <a href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-coffee">
                                                <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path><path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path><line x1="6" y1="1" x2="6" y2="4"></line><line x1="10" y1="1" x2="10" y2="4"></line><line x1="14" y1="1" x2="14" y2="4"></line>
                                            </svg>
                                            {newData.role}</a>
                                    </li>
                                    <li className="contacts-block__item">
                                        {!doEdit ? <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline>
                                            </svg>{newData.email}
                                        </> : <input type="text"
                                            onChange={(event: React.ChangeEvent<{ value: any }>) => {
                                                setNewData({ ...newData, email: event.target.value });
                                            }}
                                            value={newData.email} name="txt" placeholder="Enter email" className="form-control" />}
                                    </li>
                                    <li className="contacts-block__item">
                                        {!doEdit ? <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                            {newData.phoneNumber}
                                        </> : <input type="text"
                                            onChange={(event: React.ChangeEvent<{ value: any }>) => {
                                                setNewData({ ...newData, phoneNumber: event.target.value });
                                            }}
                                            value={newData.phoneNumber} name="txt" placeholder="Enter phone number" className="form-control" />}
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">
                    <div className="bio layout-spacing ">
                        <div className="widget-content widget-content-area">
                            <h3 className="">Bio</h3>
                            <p>{!doEdit ? newData.bio : <textarea
                                onChange={(event: React.ChangeEvent<{ value: any }>) => {
                                    setNewData({ ...newData, bio: event.target.value });
                                }}
                                value={newData.bio}
                                className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex" }} className="widget-content widget-content-area">
                {getUserCard(useListResult)}
            </div>
        </>
    )
}


const getUserCard = (userList: User[]) => {
    return userList.map(user => {
        return (
            <div key={user.id} className="card component-card_4">
                <div className="card-body">
                    <div className="user-profile">
                        <img src={user.picture || "assets/img/90x90.jpg"} className="" alt="..." />
                    </div>
                    <div className="user-info">
                        <h5 className="card-user_name">{user.name}</h5>
                        <div className="card-star_rating">
                            <span className="badge badge-primary">{user.role}</span>
                        </div>
                        <p className="card-user_occupation">{user.phoneNumber}</p>
                        <p className="card-user_occupation">{user.email}</p>
                        <p className="card-text"> {user.bio}</p>
                    </div>
                </div>
            </div>
        )
    })
}


export default () => {
    const jwt = jwt_decode<JwtToken>(keycloak.idToken || "");
    const [useResult, reexecuteQueryUser] = useQuery({ query: GetUser, variables: { id: jwt.sub } });
    const [useListResult] = useQuery({ query: getAllUser });

    const { data, fetching, error } = useResult;
    if (fetching || useListResult.fetching) {
        return <div>Loading...</div>
    }
    let userData = data.user_by_pk;
    return (
        <>
            <UserForm data={userData} useListResult={useListResult.data.user} />
        </>
    )
}