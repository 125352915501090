import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';


export const WrapperCss: React.CSSProperties = {
    display: 'flex',
    width:'100%',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: 'rgb(197 201 212)',
    borderStyle: 'dashed',
    backgroundColor: 'rgb(241 241 241)',
    color: 'rgb(189, 189, 189)',
    outline: 'none'
}


export type FleUploadProps = {
    displayElement?: React.ReactElement,
    maxCount?: number,
    onDrop: Function,
    hideTextOnPreview?: boolean
}

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function Previews(props: FleUploadProps) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: (props?.maxCount || 1),
        onDrop: acceptedFiles => {
            props.onDrop(acceptedFiles);
            // @ts-ignore
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        // @ts-ignore
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    // @ts-ignore
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        // @ts-ignore
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <>
            {
                // @ts-ignore
                <div {...getRootProps({ className: 'dropzone' })} style={WrapperCss}                >
                    <input {...getInputProps()} />
                    {
                        (files.length > 0 && props.hideTextOnPreview) ? ''
                            : (props.displayElement || <p>Drag 'n' drop some files here, or click to select files</p>)
                    }
                </div>
            }
            {
                // @ts-ignore
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            }
        </>
    );
}

export default Previews;