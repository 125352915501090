import React from 'react';
import makeAnimated from 'react-select/animated';
// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useQuery, useMutation } from 'urql';
import { NewsTag } from '../types'
const animatedComponents = makeAnimated();

const getNewsTagsQuery = `
query {
    newsTags {
      value
      label
    }
  }
`
const insertNewsTagsQuery = `
mutation ($newsTags: [newsTags_insert_input!]!) {
    insert_newsTags(objects: $newsTags) {
      returning {
        label
        value
      }
    }
  }
`
type NewsTagsProps = {
  onChange: Function
  initialValue?: NewsTag[]
}
export default (props: NewsTagsProps) => {

  const [getNewsTagsResult, reexecuteQueryNewsTags] = useQuery({ query: getNewsTagsQuery });
  const [insertNewsTagsResult, insertNewsTags] = useMutation(insertNewsTagsQuery);
  const { data, fetching, error } = getNewsTagsResult;
  if (fetching || error) {
    return <p>loading...</p>
  }
  const result: NewsTag[] = data.newsTags;
  const defaultValue = props.initialValue
  const addNewsTag = async (newsTags: NewsTag[]) => {
    let result = await insertNewsTags({
      newsTags
    })
    // @ts-ignore
    return result.data.insert_newsTags.returning as NewsTag[]
  }
  return (
    <CreatableSelect
      isClearable
      placeholder="Select / Type tags"
      defaultValue={defaultValue}
      label="newsTagSelect"
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      onChange={async (data: any) => {
        let newData: NewsTag[] = [];
        const newTags = data.filter((x: any) => x.__isNew__);
        if (newTags.length > 0)
          newData = await addNewsTag(newTags.map((x: NewsTag) => ({ label: x.label, value: x.value })))
        props.onChange([...data, ...newData])
      }}
      // @ts-ignore
      options={result} />
  )
};