import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Header from './header';
import NaveBar from './navBar';
import Footer from './footer';
import App from '../App';

function Main() {
    return (
        <Router>
            <Header />
            <div className="main-container" id="container">

                <div className="overlay"></div>
                <div className="search-overlay"></div>
                <NaveBar />
                <div id="content" className="main-content">
                    <div className="layout-px-spacing">
                        <App />
                    </div>
                    <Footer />
                </div>

            </div>
        </Router >
    );
}


export default Main;
