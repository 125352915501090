import React from 'react';
import "./Accordion.css";

type AccordionItem = {
    body: React.ReactElement | string,
    header?: React.ReactElement,
    key: string,
    title: React.ReactElement | string,
}

type AccordionProps = {
    data: AccordionItem[],
    id: string
}

export default ({ data, id }: AccordionProps) => {
    const [openId, setOpenId] = React.useState<number>(0);

    return (
        <div id={"accordion_root_" + id} className="no-outer-spacing accordion_wrapper" >
            {
                data.map((elem, index) => {
                    return (
                        <div key={elem.key} className="card">
                            <div className="card-header" id={"heading_" + elem.key} onClick={(event: React.MouseEvent) => {
                                setOpenId(index)
                                event.stopPropagation();
                            }}>
                                <section className="mb-0 mt-0">
                                    <div role="menu" className="p-0">
                                        {elem.title}
                                    </div>
                                </section>
                            </div>

                            <div id={"accordion_" + elem.key} className={(openId == index) ? "" : "collapse"}>
                                <div className="card-body accordion_body">
                                    {elem.body}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    )
}