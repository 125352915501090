import React, { Component } from 'react';
import * as QuillClass from "quill";
import Quill from "quill";
import { Attachment } from '../types';
// @ts-ignore
import toHtml from 'string-to-html';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import "./Editor.css";

export interface UnprivilegedEditor {
  getLength(): number;
  getText(index?: number, length?: number): string;
  getHTML(): string;
  getBounds(index: number, length?: number): QuillClass.BoundsStatic;
  getSelection(focus?: boolean): QuillClass.RangeStatic;
  getContents(index?: number, length?: number): QuillClass.DeltaStatic;
}
const GCS_SERCER_URL = 'https://api.nivendha.me/uploadImage'
const quillModules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }, { font: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['image']
      // ['clean'],
    ],
  },
};
export const quillOptions = {
  theme: 'snow',
  formats: [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent'
  ],
  placeholder: 'Write here ...',
  modules: quillModules,
};

export default class Editor extends React.Component<{
  onEditorStateChange: Function
  intialValue?: string
  copyValue?: string
  onCoppySuccess?: Function
  uploadURl: string
  onChangeImg?: Function
}, { value: string }>  {
  imageList: Attachment[] = [];
  constructor(props: any) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.state = { value: (this.props.intialValue || '') };

  }

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }, { font: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['image']
        // ['clean'],
      ],
    },
    imageUploader: {
      upload: (file: File) => {
        var formdata = new FormData();
        formdata.append("file", file, file.name);
        formdata.append("path", this.props.uploadURl);
        return fetch(`${GCS_SERCER_URL}`, {
          method: 'POST',
          body: formdata
        }).then(response => response.json())
          .then(result => {
            console.log(result);
            return result.data[0];
          })
          .catch(error => console.log('error', error));
      }
    }
  }
  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot" // #5 Optinal if using custom formats
  ];
  handleImageChange(content: string, source: QuillClass.Sources) {
    if (this.props.onChangeImg && source != 'api') {
      const dom = toHtml(content)
      const imgElements = dom.querySelectorAll('img');
      if (imgElements && imgElements.length != this.imageList.length) {
        let fileURL: string, fileURLSplit: string[],
          uuid: string, uuidSplit: string[], fileName: string, previewURL: string;
        let imgList: Attachment[] = [];
        for (let i = 0; i < imgElements.length; i++) {
          fileURL = imgElements[i].getAttribute('src');
          fileURLSplit = fileURL.split('/');
          fileName = fileURLSplit[fileURLSplit.length - 1];
          uuidSplit = fileName.split('#');
          uuid = uuidSplit[uuidSplit.length - 1];
          imgList.push({ fileName, previewURL: fileURL, uuid, fileURL })
        }
        this.imageList = imgList;
        this.props.onChangeImg(imgList);
      }
    }
  }
  handleChange(content: string,
    delta: QuillClass.Delta,
    source: QuillClass.Sources,
    editor: UnprivilegedEditor) {
    this.setState({ value: content });
    this.props.onEditorStateChange(editor, source, content);
    this.handleImageChange(content, source)
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.copyValue != "") {
      this.handleImageChange(this.state.value, 'user')
    }
  }
  static getDerivedStateFromProps(props: any, state: any) {
    if (props.copyValue && props.copyValue !== '') {
      props.onCoppySuccess();
      return {
        value: props.copyValue
      };
    }
    // Return null to indicate no change to state.
    return null;
  }
  render() {

    return (
      <ReactQuill
        style={{ width: 'auto', height: '310px' }}
        value={this.state.value}
        placeholder='Write here ...'
        modules={this.modules}
        formats={this.formats}
        onChange={this.handleChange}
      />
    );
  }
}


// IMAGE UPLOADER CODE

const InlineBlot = Quill.import("blots/block");

class LoadingImage extends InlineBlot {
  static create(src: any) {
    const node = super.create(src);
    if (src === true) return node;

    const image = document.createElement("img");
    image.setAttribute("src", src);
    node.appendChild(image);
    return node;
  }
  deleteAt(index: Number, length: Number) {
    super.deleteAt(index, length);
    this.cache = {};
  }
  static value(domNode: any) {
    const { src, custom } = domNode.dataset;
    return { src, custom };
  }
}

LoadingImage.blotName = "imageBlot";
LoadingImage.className = "image-uploading";
LoadingImage.tagName = "span";



class ImageUploader {
  quill: any;
  options: any;
  range: any;
  fileHolder: any;

  constructor(quill: any, options: any) {
    this.quill = quill;
    this.options = options;
    this.range = null;

    if (typeof this.options.upload !== "function")
      console.warn(
        "[Missing config] upload function that returns a promise is required"
      );

    var toolbar = this.quill.getModule("toolbar");
    toolbar.addHandler("image", this.selectLocalImage.bind(this));

    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.quill.root.addEventListener("drop", this.handleDrop, false);
    this.quill.root.addEventListener("paste", this.handlePaste, false);
  }

  selectLocalImage() {
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement("input");
    this.fileHolder.setAttribute("type", "file");
    this.fileHolder.setAttribute("accept", "image/*");
    this.fileHolder.setAttribute("style", "visibility:hidden");

    this.fileHolder.onchange = this.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder);
    });
  }

  handleDrop(evt: React.DragEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    if (
      evt.dataTransfer &&
      evt.dataTransfer.files &&
      evt.dataTransfer.files.length
    ) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.startContainer,
            range.startOffset,
            range.startContainer,
            range.startOffset
          );
        }
      } else {
        const selection = document.getSelection();
        const range = document.caretPositionFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.offsetNode,
            range.offset,
            range.offsetNode,
            range.offset
          );
        }
      }

      this.range = this.quill.getSelection();
      let file = evt.dataTransfer.files[0];

      setTimeout(() => {
        this.range = this.quill.getSelection();
        this.readAndUploadFile(file);
      }, 0);
    }
  }

  handlePaste(evt: React.ClipboardEvent) {
    let clipboard = evt.clipboardData //|| window.clipboardData;

    // IE 11 is .files other browsers are .items
    if (clipboard && (clipboard.items || clipboard.files)) {
      let items = clipboard.items || clipboard.files;
      const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

      for (let i = 0; i < items.length; i++) {
        if (IMAGE_MIME_REGEX.test(items[i].type)) {
          // @ts-ignore
          let file = items[i].getAsFile ? items[i].getAsFile() : items[i];

          if (file) {
            this.range = this.quill.getSelection();
            evt.preventDefault();
            setTimeout(() => {
              this.range = this.quill.getSelection();
              this.readAndUploadFile(file);
            }, 0);
          }
        }
      }
    }
  }

  readAndUploadFile(file: any) {
    let isUploadReject = false;

    const fileReader = new FileReader();

    fileReader.addEventListener(
      "load",
      () => {
        if (!isUploadReject) {
          let base64ImageSrc = fileReader.result;
          this.insertBase64Image(base64ImageSrc);
        }
      },
      false
    );

    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.options.upload(file).then(
      (imageUrl: string) => {
        this.insertToEditor(imageUrl);
      },
      (error: string) => {
        isUploadReject = true;
        this.removeBase64Image();
        console.warn(error);
      }
    );
  }

  fileChanged() {
    const file = this.fileHolder.files[0];
    this.readAndUploadFile(file);
  }

  insertBase64Image(url: any) {
    const range = this.range;
    this.quill.insertEmbed(
      range.index,
      LoadingImage.blotName,
      `${url}`,
      "api"
    );
  }

  insertToEditor(url: string) {
    const range = this.range;
    // Delete the placeholder image
    this.quill.deleteText(range.index, 3, "api");
    // Insert the server saved image
    this.quill.insertEmbed(range.index, "image", `${url}`, "user");

    range.index++;
    this.quill.setSelection(range, "user");
  }

  removeBase64Image() {
    const range = this.range;
    this.quill.deleteText(range.index, 3, "api");
  }
}

Quill.register({ "formats/imageBlot": LoadingImage });
Quill.register("modules/imageUploader", ImageUploader);
